import React, { useEffect } from 'react'

import { useSingleAccessLink } from 'models/admin'


const AdminLayout: React.FC = ({ children }) => {
  const singleAccessLink = useSingleAccessLink()

  useEffect(() => {
    const originalFetch = window.fetch

    window.fetch = (input, init) => {
      if (window.token && !init?.headers?.Authorization) {
        console.log('Missed token', input)
      }

      return originalFetch(input, {
        ...init,
        headers: {
          ...init?.headers,
          Authorization: window.token
            ? `Bearer ${window.token}`
            : init?.headers?.Authorization,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (singleAccessLink) {
      const root = document.getElementById('root')

      if (root) {
        root.style.borderTopLeftRadius = '0'
        root.style.maxWidth = '100%'

        return () => {
          if (root) {
            root.style.borderTopLeftRadius = ''
            root.style.maxWidth = ''
          }
        }
      }
    }
  }, [ singleAccessLink ])

  return (
    <>
      {children}
    </>
  )
}


export default AdminLayout
